import { useNavigate } from "react-router-dom";
import { userSignup } from "../../services/authServices";

const SignUp = () => {

    const navigate = useNavigate();

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        
        let newUser = {firstName:  e.target.elements['firstName'].value, lastName:  e.target.elements['lastName'].value, email: e.target.elements['email'].value, password:  e.target.elements['password'].value}
        console.log(newUser)
        /* setUser(newUser) */
        let result = await userSignup(newUser)
        if(result){
            localStorage.setItem('user', newUser)
            navigate('/')
        }
    }

    return (
        <>
            <h1>Pagina SignUp</h1>
            <form onSubmit={handleOnSubmit}>
                <label htmlFor="email">email</label>
                <input type="text" id="email" /> <br />
                <label htmlFor="password">password</label>
                <input type="password" id="password" /> <br />
                <label htmlFor="firstName">nome</label>
                <input type="text" id="firstName" /> <br />
                <label htmlFor="lastName">cognome</label>
                <input type="text" id="lastName" /> <br />
                <button type="submit">registrati</button>
            </form>
        </>
    );
};

export default SignUp;
