import { useCallback, useContext, useEffect, useState } from "react";
import { getAllCategory } from "../../services/categoriesService";
import {
    getAllProduct,
    getAllProductWhereArray,
} from "../../services/productsService";
import { IconSend, IconSearch } from "../Icon/Icon";
import { getAllStore } from "../../services/storesService";
import Toggle from "../Toggle";
import { AuthTokenContext } from "../../context/AuthTokenContext";

const Search = ({
    code,
    setCode,
    handleChangeToggle,
    handleChangeSearch,
    setProducts,
}) => {
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const token = useContext(AuthTokenContext);

    const retrieveCategories = useCallback(async () => {
        let res = await getAllCategory(token);
        setCategories(res);
    }, [token]);

    const retrieveStores = useCallback(async () => {
        let res = await getAllStore(token);
        setStores(res);
    }, [token]);

    const resetTable = () => {
        let table = document.getElementById("ProductList");
        let tr = table.getElementsByTagName("tr");
        let td = table.getElementsByTagName("td");
        let indexRow = 1;
        let indexCol = 0;
        let nCol = tr[0].childElementCount;

        for (indexCol = 0; indexCol < td.length; indexCol = indexCol + nCol) {
            tr[indexRow].style.display = "";
            indexRow++;
        }
    };

    const resetSelect = () => {
        document.getElementById("selectCategory").value = "all";
        document.getElementById("selectStore").value = "all";
    };

    const clearAll = () => {
        setAllProduct();
        resetSelect();
        resetTable();
        setCode("");
    };

    const handleKeyUp = () => {
        console.log("handleKeyUp")
        let input = document.getElementById("inputSearch");
        let filter = input.value.toLowerCase();

        let table = document.getElementById("ProductList");
        let tr = table.getElementsByTagName("tr");
        let td = table.getElementsByTagName("td");
        let indexRow = 1;
        let indexCol = 0;
        let nCol = tr[0].childElementCount;

        for (indexCol = 0; indexCol < td.length; indexCol = indexCol + nCol) {
            let str = td[indexCol].textContent.toLowerCase();
            if (str.includes(filter)) {
                console.log(tr[indexRow].textContent)
                /* tr[indexRow].style.display = ""; */
                tr[indexRow].setAttribute("style", "display: table-row !important")
            } else {
                if (tr[indexRow]){ 
                    /* tr[indexRow].style.display = "none"; */
                    tr[indexRow].setAttribute("style", "display: none !important")
                }
            }
            indexRow++;
        }
    };

    const handleSelectCategory = async (e) => {
        if (e.target.value === "all") {
            let result = await getAllProduct(token);
            await setProducts(result);
        } else {
            let productFiltered = await getAllProductWhereArray(
                "Categories.id",
                e.target.value,
                token
            );
            await setProducts(productFiltered);
        }
    };

    const handleSelectStore = async (e) => {
        if (e.target.value === "all") {
            let result = await getAllProduct(token);
            await setProducts(result);
        } else {
            let productFiltered = await getAllProductWhereArray(
                "Stores.id",
                e.target.value,
                token
            );
            await setProducts(productFiltered);
        }
    };

    const setAllProduct = async () => {
        let result = await getAllProduct(token);
        await setProducts(result);
    };

    useEffect(() => {
        retrieveCategories();
        retrieveStores();
    }, [retrieveCategories, retrieveStores]);

    return (
        <div id="divSearch" className="mb-8">
            <label htmlFor="inputSearch">Ricerca prodotto</label>
            <div className="flex flex-col lg:flex-row gap-4 relative">
                <div className="relative w-full">
                    <div className="absolute left-2.5 top-2.5">
                        <IconSearch width="1.5rem" height="1.5rem" />
                    </div>
                    <input
                        id="inputSearch"
                        onKeyUp={handleKeyUp}
                        placeholder="Nome prodotto o codice..."
                        onChange={handleChangeSearch}
                        value={code}
                        className="input-secondary pl-10"
                        type="text"
                    />
                    <div className="absolute right-4 top-2.5">
                        <button className="" type="button" onClick={clearAll}>
                            Reset
                        </button>
                    </div>
                </div>
                <div className="flex flex-row flex-wrap gap-1">
                    <button
                        className="btn-primary dark:bg-neutral-700"
                        type="submit"
                    >
                        <IconSend />
                        Invia
                    </button>
                </div>
            </div>

            <div className="flex flex-row flex-wrap gap-1 my-2 items-center justify-between">
                <div className="flex flex-row flex-wrap gap-1 ">
                    <select
                        onChange={handleSelectCategory}
                        className="select-primary"
                        id="selectCategory"
                    >
                        <option value="all"> Tutte le categorie </option>
                        {categories.map((cat, index) => (
                            <option key={index} value={cat.id}>
                                {cat.name}
                            </option>
                        ))}
                    </select>
                    <select
                        onChange={handleSelectStore}
                        className="select-primary"
                        id="selectStore"
                    >
                        <option value="all"> Tutti i negozi</option>
                        {stores.map((st, index) => (
                            <option key={index} value={st.id}>
                                {st.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <Toggle
                label="Ricerca e aggiorna quantità"
                handleChangeToggle={handleChangeToggle}
            />
        </div>
    );
};

export default Search;
