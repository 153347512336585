import { useContext, useEffect, useState } from "react";
import { AuthTokenContext } from "../../context/AuthTokenContext";
import {
    deleteCategoryAPI,
    getAllCategory,
    postCategoryAPI,
    putCategoryAPI,
} from "../../services/categoriesService";
import {
    deleteStoreAPI,
    getAllStore,
    postStoreAPI,
    putStoreAPI,
} from "../../services/storesService";
import SettingsCard from "./SettingsCard";

const Settings = ({
    daysToNotification,
    setDaysToNotification,
}) => {
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const token = useContext(AuthTokenContext)

    const retrieveCategories = async () => {
        let res = await getAllCategory(token);
        setCategories(res);
    };

    const retrieveStores = async () => {
        let res = await getAllStore(token);
        setStores(res);
    };

    const handleChangeNotificationExpire = (e) => {
        console.log(e.target.value);
        setDaysToNotification(e.target.value);
    };

    useEffect(() => {
        retrieveCategories();
        retrieveStores();
    }, []);

    return (
        <div>
            <h2 className="text-xl my-4 font-extrabold cursor-pointer">
                Impostazioni
            </h2>
            <div className="flex flex-wrap gap-10">
                <SettingsCard
                    data={categories}
                    postFunc={postCategoryAPI}
                    updateFunc={putCategoryAPI}
                    deleteFunc={deleteCategoryAPI}
                    title="Categorie"
                    details="bla bla bla"
                    labelNew="Nome nuova categoria"
                    buttonNew="Aggiungi nuova categoria"
                />
                <SettingsCard
                    data={stores}
                    postFunc={postStoreAPI}
                    updateFunc={putStoreAPI}
                    deleteFunc={deleteStoreAPI}
                    title="Negozi"
                    details="bla bla bla"
                    labelNew="Nome nuovo negozio"
                    buttonNew="Aggiungi nuovo negozio"
                />
                <div className="max-w-2xl h-fit  overflow-hidden bg-white shadow sm:rounded-lg border border-gray-300">
                    <h3 className="text-lg p-6 font-medium leading-6 text-gray-900">
                        Giorni notifica scadenza
                    </h3>
                    <div className="border-t border-gray-200 p-6">
                        <label htmlFor="selectDaysToNotification">
                            Seleziona il numero di giorni:
                        </label>
                        <select
                            id="selectDaysToNotification"
                            onChange={handleChangeNotificationExpire}
                            value={daysToNotification}
                            className="border border-gray-500 rounded-lg w-fit py-2 px-2 ml-4"
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="3">3</option>
                            <option value="7">7</option>
                            <option value="15">15</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;
