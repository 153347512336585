import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import {
    printDate,
    calculateExpire,
    getAllProduct,
    putAPIFull,
    getAllProductOrdered,
    daysTwoDate,
    getAllProductWhereArray,
} from "../../services/productsService";
import { getCategory } from "../../services/categoriesService";
import { getStore } from "../../services/storesService";

import Tag from "../Tag/Tag";
import Modal from "../Modal";
import ProductUpdate from "../ProductUpdate";
import { IconDraw, IconTriangleDown } from "../Icon/Icon";
import { AuthTokenContext } from "../../context/AuthTokenContext";

const ProductList = ({ products, setProducts, show }) => {
    const [product, setProduct] = useState({});
    const [categories, setCategories] = useState([]);
    const [stores, setStores] = useState([]);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [orderByQuantity, setOrderByQuantity] = useState(false);

    const handleClickUpdate = (myProduct) => {
        setProduct(myProduct);
        setIsOpen(!modalIsOpen);
    };

    const handleChangeBuyBack = async (e, id) => {
        let newArray = [...products];
        let prodFind = products.findIndex((prod) => prod.id === id);
        newArray[prodFind].buyBack = Boolean(e.target.checked);
        await putAPIFull(id, newArray[prodFind], token);
        setProducts(newArray);
        toast.success(`codice=${id} è stato aggiunto nella lista della spesa`);
    };

    const token = useContext(AuthTokenContext);

    const handleClickCat = async (idCat) => {
        let result = await getCategory(idCat, token);
        setProducts(result.Products);
    };

    const handleClickStore = async (idStore) => {
        let result = await getStore(idStore);
        setProducts(result.Products);
    };

    const handleOrder = async (e, order, orderBy) => {
        let productsOdered;
        if (orderByQuantity === false) {
            productsOdered = await getAllProductOrdered(order, "ASC", token);
            setOrderByQuantity(true);
        } else {
            productsOdered = await getAllProductOrdered(order, "DESC", token);
            setOrderByQuantity(false);
        }

        setProducts(productsOdered);
    };

    const handleClickTagCategory = async (id) => {
        let productFiltered = await getAllProductWhereArray(
            "Categories.id",
            id,
            token
        );
        await setProducts(productFiltered);
    };

    const handleClickTagStore = async (id) => {
        let productFiltered = await getAllProductWhereArray(
            "Stores.id",
            id,
            token
        );
        await setProducts(productFiltered);
    };

    const handleQuantityUpdate = async (e, product, op) => {
        let newArray = [...products];
        let prodFind = products.findIndex((prod) => prod.id === product.id);
        let newProd;
        if (op === "minus") {
            if (product.quantity > 0) {
                newProd = {
                    ...product,
                    quantity: String(parseInt(product.quantity) - 1),
                };
                newArray[prodFind].quantity = String(
                    parseInt(product.quantity) - 1
                );
            }
        } else if (op === "plus") {
            newProd = {
                ...product,
                quantity: String(parseInt(product.quantity) + 1),
            };
            newArray[prodFind].quantity = String(
                parseInt(product.quantity) + 1
            );
        }
        await putAPIFull(product.id, newProd, token);
        setProducts(newArray);
        toast.success(`codice=${product.id} è stato aggiornato`);
    };

    useEffect(() => {
        setStores([]);
        setCategories([]);
    }, []);

    useEffect(() => {
        const setAllProduct = async () => {
            let result = await getAllProduct(token);
            await setProducts(result);
        };
        setAllProduct();
    }, [modalIsOpen, setProducts, token]);

    return (
        <div>
            <Modal
                modalIsOpen={modalIsOpen}
                setIsOpen={setIsOpen}
                position="half-page"
                title={"modale prodotto" + product.id}
            >
                <ProductUpdate
                    id={product.id}
                    setProducts={setProducts}
                    setIsOpen={setIsOpen}
                />
            </Modal>
            {categories.length !== 0 &&
                categories.map((cat, index) => (
                    <button
                        key={index}
                        onClick={() => handleClickCat(cat.id)}
                        className="button-bordered"
                    >
                        {cat.name}
                    </button>
                ))}

            {stores.length !== 0 &&
                stores.map((store, index) => (
                    <button
                        key={index}
                        onClick={() => handleClickStore(store.id)}
                        className="button-bordered"
                    >
                        {store.name}
                    </button>
                ))}

            <div className="min-w-full overflow-scroll sm:overflow-auto sm:rounded-lg sm:shadow dark:border-white sm:border-2 sm:border-gray-900 ">
                <table
                    id="ProductList"
                    className="min-w-full leading-normal flex sm:table"
                >
                    <thead className="hidden sm:table-row-group">
                        <tr>
                            <th
                                scope="col"
                                className="px-5 py-3 text-md font-bold text-left border-b border-gray-900 dark:border-white"
                            >
                                Prodotto
                            </th>
                            <th
                                scope="col"
                                className="px-5 py-3 text-md sm:whitespace-nowrap h-max font-bold text-left border-b border-gray-900 dark:border-white"
                            >
                                Quantità
                                <button
                                    onClick={(e) =>
                                        handleOrder(
                                            e,
                                            "quantity",
                                            orderByQuantity
                                        )
                                    }
                                >
                                    <IconTriangleDown />
                                </button>
                            </th>
                            <th
                                scope="col"
                                className="px-5 py-3 text-md font-bold text-left border-b border-gray-900 dark:border-white"
                            >
                                Da comprare
                            </th>
                            {show.expire ? (
                                <th
                                    scope="col"
                                    className="px-5 py-3 text-md font-bold text-left border-b border-gray-900 dark:border-white"
                                >
                                    Scadenza
                                </th>
                            ) : null}
                            {show.actions ?  <th
                                scope="col"
                                className="px-5 py-3 text-md font-bold text-left border-b border-gray-900 dark:border-white"
                            >
                                Azioni
                            </th> : null}
                           
                        </tr>
                    </thead>
                    <tbody>
                        {/* show={{"details": true, "quantity": true, "toBuy": true, "quantityToBuy": false, "expire": true, "action": true}} */}
                        {products &&
                            products.map((product, index) => (
                                <tr
                                    key={index}
                                    className="flex flex-col border-2 border-gray-800 rounded-lg shadow my-4 sm:table-row sm:border-none sm:rounded-none sm:shadow-none sm:my-0 "
                                >
                                    <td className="px-5 py-2 sm:py-5 text-md sm:border-b sm:border-gray-900 sm:dark:border-white">
                                        {/* da eliminare */}{" "}
                                        <div>
                                            <span className="text-lg font-medium">
                                                ID:{" "}
                                            </span>
                                            {product.id}
                                        </div>{" "}
                                        {/* da eliminare */}
                                        <div>
                                            <span className="text-lg font-medium">
                                                Codice:{" "}
                                            </span>
                                            {product.code}
                                        </div>
                                        <div>
                                            {isMobile ? (
                                                <span className="text-lg font-medium">
                                                    Nome:{" "}
                                                </span>
                                            ) : null}
                                            {product.name}
                                        </div>
                                        {product.brand ? (
                                            <div>
                                                {isMobile ? (
                                                    <span className="text-lg font-medium">
                                                        Brand:{" "}
                                                    </span>
                                                ) : null}
                                                {product.brand}
                                            </div>
                                        ) : null}
                                        {product.Categories ? (
                                            <div className="flex flex-row flex-wrap sm:flex-nowrap">
                                                {isMobile ? (
                                                    <span className="text-lg font-medium">
                                                        Categorie:{" "}
                                                    </span>
                                                ) : null}
                                                {product.Categories.map(
                                                    (category, index) => (
                                                        <Tag
                                                            key={index}
                                                            id={category.id}
                                                            onClick={
                                                                handleClickTagCategory
                                                            }
                                                        >
                                                            {category.name}
                                                        </Tag>
                                                    )
                                                )}
                                            </div>
                                        ) : null}
                                        {product.Stores ? (
                                            <div className="flex flex-row">
                                                {isMobile ? (
                                                    <span className="text-lg font-medium">
                                                        Negozi:
                                                    </span>
                                                ) : null}
                                                {product.Stores.map(
                                                    (store, index) => (
                                                        <Tag
                                                            key={index}
                                                            type="store"
                                                            id={store.id}
                                                            onClick={
                                                                handleClickTagStore
                                                            }
                                                        >
                                                            {store.name}
                                                        </Tag>
                                                    )
                                                )}
                                            </div>
                                        ) : null}
                                    </td>

                                    <td className="px-5 py-2 sm:py-5 text-md dark:text-white sm:border-b sm:border-gray-900 sm:dark:border-white sm:whitespace-nowrap">
                                        {isMobile ? (
                                            <span className="text-lg font-medium">
                                                Quantità:{" "}
                                            </span>
                                        ) : null}
                                        <button
                                            onClick={(e) =>
                                                handleQuantityUpdate(
                                                    e,
                                                    product,
                                                    "minus"
                                                )
                                            }
                                            className="w-8 h-8 mr-4 text-base rounded-full dark:text-white text-black border-2 border-gray-900 dark:border-white checked:"
                                        >
                                            -
                                        </button>
                                        {product.quantity}
                                        <button
                                            onClick={(e) =>
                                                handleQuantityUpdate(
                                                    e,
                                                    product,
                                                    "plus"
                                                )
                                            }
                                            className="w-8 h-8 ml-4 text-base rounded-full dark:text-white text-black border-2 border-gray-900 dark:border-white"
                                        >
                                            +
                                        </button>
                                        <br />
                                        {product.unitOfMeasure}
                                    </td>
                                    <td className="px-5 py-2 sm:py-5 text-md dark:text-white sm:border-b sm:border-gray-900 sm:dark:border-white">
                                        {isMobile ? (
                                            <span className="text-lg font-medium">
                                                Da comprare:
                                            </span>
                                        ) : null}
                                        <input
                                            type="checkbox"
                                            checked={product.buyBack}
                                            id={"buyBack-" + product.id}
                                            onChange={(e) =>
                                                handleChangeBuyBack(
                                                    e,
                                                    product.id
                                                )
                                            }
                                            className=" form-tick appearance-none h-6 w-6 border-2 border-gray-900 dark:border-white rounded-md checked:bg-check checked:bg-indigo-600"
                                        />
                                    </td>
                                    {show.expire ? <td className="px-5 py-2 sm:py-5 text-md sm:border-b sm:border-gray-900 sm:dark:border-white">
                                        {isMobile ? (
                                            <span className="text-lg font-medium">
                                                Scadenza:{" "}
                                            </span>
                                        ) : null}
                                        <div>
                                           {/*  {product.expiredAt
                                                ? calculateExpire(
                                                      product.purchasedAt,
                                                      product.expiredAt
                                                  ) +
                                                  " in data: " +
                                                  printDate(product.expiredAt)
                                                : printDate(product.expiredAt)} */}
                                                 {product.expiredAt
                                                ? calculateExpire(
                                                      product.purchasedAt,
                                                      product.expiredAt
                                                  ) : printDate(product.expiredAt)}
                                        </div>
                                        {product.expiredAt ? (
                                            <progress
                                                id="file"
                                                value={daysTwoDate(
                                                    product.purchasedAt,
                                                    Date.now()
                                                )}
                                                max={Math.abs(
                                                    daysTwoDate(
                                                        product.purchasedAt,
                                                        product.expiredAt
                                                    )
                                                )}
                                                className={
                                                    "w-100% sm:w-full " +
                                                    (daysTwoDate(
                                                        Date.now(),
                                                        product.expiredAt
                                                    ) < 0
                                                        ? " progress-red"
                                                        : daysTwoDate(
                                                              Date.now(),
                                                              product.expiredAt
                                                          ) <= 7
                                                        ? " progress-yellow"
                                                        : null)
                                                }
                                            >
                                                {daysTwoDate(
                                                    product.purchasedAt,
                                                    Date.now()
                                                )}
                                            </progress>
                                        ) : null}
                                    </td> : null}
                                    {show.actions ? 
                                    <td className="px-5 py-2 sm:py-5 text-md sm:border-b dark:text-white sm:border-gray-900 sm:dark:border-white">
                                        <button
                                            className="btn-primary dark:bg-neutral-700"
                                            onClick={() =>
                                                handleClickUpdate(product)
                                            }
                                            role="menuitem"
                                        >
                                            <IconDraw />
                                            Mostra dettagli
                                        </button>
                                    </td> : null}
                                    
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductList;
