import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import "../../index.css";
import { IconCheck, IconAdd } from "../Icon/Icon";

const DropdownMultiple = ({
    optionsList,
    optionListSelected,
    placeholder,
    label,
    handleSelect,
    id,
    disabled
}) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState([]);
    const [isFocused, setIsFocused] = useState(null);
    const optionsRef = useRef([]);
    const ref = useRef(null);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const refreshSelectedOption = useCallback(() => {
        setSelectedOption((selectedOption) =>
            selectedOption.map((optionElement) => optionElement)
        );
    }, [setSelectedOption]);

    const toggleOptions = () => {
        setIsOptionsOpen(!isOptionsOpen);
    };

    const deleteOption = (option) => {
        console.log("deleteOption ->"+option)
        const index = selectedOption.indexOf(option);
        let newSelectedOption = [...selectedOption]
        newSelectedOption.splice(index, 1);
        if(newSelectedOption.length === 0){
            console.log("voglio eliminare l'unico elemento rimasto")
        }
        console.log("deleteOption ->"+newSelectedOption)
        handleSelect(newSelectedOption);
        setSelectedOption(newSelectedOption);
        refreshSelectedOption();
        console.log("\n")
    };

    const setSelected = (option) => {
        console.log("setSelected ->"+option)
        if (!selectedOption.includes(option)) {
            handleSelect([...selectedOption, option]);
            setSelectedOption([...selectedOption, option]);
        } else {
            deleteOption(option);
        }
        refreshSelectedOption();
        console.log("\n")
    };

    const handleListKeyDown = (e) => {
        switch (e.key) {
            case "Escape":
                e.preventDefault();
                setIsOptionsOpen(false);
                break;
            case "ArrowUp":
                e.preventDefault();
                setIsFocused(
                    isFocused - 1 >= 0 ? isFocused - 1 : optionsList.length - 1
                );
                break;
            case "ArrowDown":
                e.preventDefault();
                setIsFocused(
                    isFocused === optionsList.length - 1 || isFocused == null
                        ? 0
                        : isFocused + 1
                );
                break;
            default:
                break;
        }
        refreshSelectedOption();
    };

    const onHoverOption = (index) => {
        setIsFocused(index);
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsOptionsOpen(false);
        }
    };

    const compare = ( a, b ) => {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
    }

    useEffect(() => {
        setSelectedOption(optionListSelected);
    }, [optionListSelected]);

    useEffect(() => {
        if (isFocused != null) {
            optionsRef.current = optionsRef.current.slice(
                0,
                optionsList.length
            );
            optionsRef.current[isFocused].focus();
        }
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, [isFocused, optionsList]);

    return (
        <div ref={ref}>
            <button
                type="button"
                id={id}
                aria-expanded={isOptionsOpen}
                aria-haspopup="listbox"
                onClick={toggleOptions}
                onKeyDown={handleListKeyDown}
                className={disabled ? "flex w-full" : "flex w-full items-center h-full p-2 border-2 border-gray-900 dark:border-white rounded-md checked:bg-gray-800 checked:dark:bg-white checked:border-transparent focus:outline-none"}
                disabled={disabled}
            >
                <div className="flex w-5/6 flex-row flex-wrap gap-2">
                    {selectedOption &&
                        selectedOption.map((option, index) => {
                            return (
                                <div
                                    tabIndex="-1"
                                    key={index}
                                    className="btn-primary"
                                >
                                    {optionsList.find(el => el.id === option).name}
                                    {disabled ? null : <svg
                                        onClick={(e) => {
                                            stopPropagation(e);
                                            deleteOption(option);
                                        }}
                                        className="flex items-center pl-2"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 320 512"
                                        width="1rem"
                                        height="1rem"
                                        aria-hidden={true}
                                    >
                                        <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                                    </svg>}
                                </div>
                            );
                        })}
                </div>
                <div id="arrow" className="flex w-1/6 justify-end">
                    {disabled ? null : isOptionsOpen ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width="15px"
                            height="15px"
                            aria-hidden={true}
                        >
                            <path d="M416 352c-8.188 0-16.38-3.125-22.62-9.375L224 173.3l-169.4 169.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25C432.4 348.9 424.2 352 416 352z" />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 448 512"
                            width="15px"
                            height="15px"
                            aria-hidden={true}
                        >
                            <path d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
                        </svg>
                    )}
                </div>
            </button>
            {isOptionsOpen ? (
                <div
                    role="listbox"
                    aria-label="lista di opzioni"
                    tabIndex="-1"
                    onKeyDown={handleListKeyDown}
                    aria-activedescendant={"button" + optionsList[isFocused]}
                    className="absolute z-10 m-2 flex h-fit flex-col flex-wrap rounded bg-white dark:bg-neutral-900 border-2 border-gray-900 dark:border-white"
                >
                    {optionsList.sort(compare).map((option, index) => (
                        <div
                            key={index}
                            role="option"
                            aria-selected={Boolean(
                                selectedOption.includes(option.id)
                            )}
                        >
                            <button
                                type="button"
                                key={index}
                                id={"button" + option.id}
                                aria-label={option.id}
                                role="checkbox"
                                aria-checked={Boolean(
                                    selectedOption.includes(option.id)
                                )}
                                tabIndex="0"
                                ref={(el) => (optionsRef.current[index] = el)}
                                onClick={() =>
                                    setSelected(option.id)
                                }
                                onMouseEnter={() => onHoverOption(index)}
                                value={option.id}
                                className="flex w-full items-center gap-2 px-4 py-2 focus:bg-indigo-200"
                            >
                                {selectedOption.includes(option.id) ? (
                                    <IconCheck />
                                ) : (
                                    <IconAdd />
                                )}
                                {option.name}
                            </button>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
};

export default DropdownMultiple;

DropdownMultiple.propTypes = {
    optionsList: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

DropdownMultiple.defaultProps = {
    optionsList: [],
    placeholder: "",
    label: "Seleziona",
};
