import { useState, useEffect, useContext } from "react";
import {
    getProduct,
    putAPIFull,
    deleteAPI,
    printDate,
    htmlPrintDate,
} from "../../services/productsService";

import { toast } from "react-toastify";
import { getAllCategory } from "../../services/categoriesService";
import { getAllStore } from "../../services/storesService";
import DropdownMultiple from "../DropdownMultiple/DropdownMultiple";
import {
    IconClose,
    IconDelete,
    IconLocked,
    IconSend,
    IconUnlocked,
} from "../Icon/Icon";
import { AuthTokenContext } from "../../context/AuthTokenContext";

const ProductUpdate = ({ id, setIsOpen }) => {
    const [product, setProduct] = useState({});
    const [categoryArray, setCategory] = useState([]);
    const [categoryArraySelected, setCategoryArraySelected] = useState([]);
    const [storeArray, setStore] = useState([]);
    const [storeArraySelected, setStoreArraySelected] = useState([]);
    const [update, setUpdate] = useState(false);
    const [time, setTime] = useState({
        type: "days",
        value: 0,
        date: new Date(),
    });
    const token = useContext(AuthTokenContext);
    const notify = () =>
        toast.success(`Il prodotto con id= ${product.id} è stato aggiornato`);

    const getAllProduct = async () => {
        console.log("getAllProduct");
        console.log("getAllProduct ->", token);
        let result = await getProduct(id, token);
        setProduct(result);
    };

    const retrieveCategory = async () => {
        let result = await getAllCategory(token);
        setCategory(result);
    };

    const retrieveStore = async () => {
        let result = await getAllStore(token);
        setStore(result);
    };

    const retrieveSelectedArrayCategory = async () => {
        let result = await getProduct(id, token);
        if (result.Categories.length !== 0) {
            let nuovoarray = [];
            result.Categories.forEach((cat) => nuovoarray.push(cat.id));
            setCategoryArraySelected(nuovoarray);
        }
    };

    const retrieveSelectedArrayStore = async () => {
        let result = await getProduct(id, token);
        if (result.Stores.length !== 0) {
            let nuovoarray = [];
            result.Stores.forEach((st) => nuovoarray.push(st.id));
            setStoreArraySelected(nuovoarray);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await putAPIFull(product.id, product, token);
        setIsOpen(false);
        notify();
        await getAllProduct(token);
    };

    const handleChange = (e) => {
        setProduct({ ...product, [e.target.name]: e.target.value });
    };

    const handleChangeBuyBack = async (e) => {
        setProduct({ ...product, buyBack: e.target.checked });
    };

    const handleChangeExpiredAt = async (e) => {
        console.log(e.target.value);
        /*  setDataExpiredAt(e.target.value); */
        setProduct({ ...product, expiredAt: e.target.value });
    };

    const handleChangePurchasedAt = async (e) => {
        console.log(e.target.value);
        /*  setPurchasedAt(e.target.value); */
        setProduct({ ...product, purchasedAt: e.target.value });
    };

    const handleClickDelete = async (code) => {
        setIsOpen(false);
        deleteAPI(code);
        const notifyDelete = () =>
            toast.success(`codice= ${code} è stato eliminato`);
        notifyDelete();
        await getAllProduct(token);
    };

    const handleSelectCategory = async (cat) => {
        if (cat.length === 0) {
            console.log("elimino l'unico");
            setProduct({
                ...product,
                categories: [],
            });
        } else {
            setProduct({
                ...product,
                categories: cat,
            });
        }
    };

    const handleSelectStore = (st) => {
        console.log("handleSelectStore -> " + st);
        setProduct({
            ...product,
            stores: st,
        });
    };

    const handleDurationSelect = (e) => {
        console.log(e);
        console.log(e.target.value, parseInt(e.target.value));

        if (e.target.value === "") {
            setTime({ ...time, value: 0, date: new Date() });
        } else {
            if (time.type === "days") {
                setTime({
                    ...time,
                    value: parseInt(e.target.value),
                    type: "days",
                    date: new Date(
                        time.date.setDate(
                            time.date.getDate() + parseInt(e.target.value)
                        )
                    ),
                });
            } else {
                setTime({
                    ...time,
                    value: parseInt(e.target.value),
                    type: "months",
                    date: new Date(
                        time.date.setMonth(
                            time.date.getMonth() + parseInt(e.target.value)
                        )
                    ),
                });
            }
        }

        setProduct({ ...product, expiredAt: htmlPrintDate(time.date) });
    };

    const handleDurationType = (e) => {
        console.log(e.target.value);
        /* let today = new Date() */
        if (e.target.value === "giorni") {
            setTime({
                ...time,
                type: "days",
                date: new Date(
                    time.date.setDate(time.date.getDate() + time.value)
                ),
            });
        } else {
            setTime({
                ...time,
                type: "months",
                date: new Date(
                    time.date.setMonth(time.date.getMonth() + time.value)
                ),
            });
        }
    };

    const handleDeleteExpire = () => {
        setProduct({ ...product, expiredAt: "" });
    };

    useEffect(() => {
        getAllProduct(token);
        retrieveCategory();
        retrieveSelectedArrayCategory();
        retrieveStore();
        retrieveSelectedArrayStore();
    }, [id]);

    return (
        <>
            <div className=" flex gap-4 justify-between">
                <button
                    onClick={() => setUpdate(!update)}
                    type="button"
                    className="btn-primary"
                >
                    {update ? <IconUnlocked /> : <IconLocked />}
                    {update ? " Disabilita modifiche" : " Abilita modifiche"}
                </button>
                <button
                    onClick={() => setIsOpen(false)}
                    className="btn-primary"
                >
                    <IconClose />
                    Chiudi
                </button>
            </div>

            <form onSubmit={handleSubmit} className=" my-4">
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formCode"
                        className="my-2 font-bold flex items-center"
                    >
                        Codice a barre
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formCode"
                            value={product.code || ""}
                            onChange={handleChange}
                            name="code"
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.code}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formName"
                        className="my-2 font-bold flex items-center"
                    >
                        Nome
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formName"
                            value={product.name || ""}
                            name="name"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.name}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formQuantity"
                        className="my-2 font-bold flex items-center"
                    >
                        Quantità:
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formQuantity"
                            value={product.quantity || ""}
                            name="quantity"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.quantity}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formUnitOfMeasure"
                        className="my-2 font-bold flex items-center"
                    >
                        Unità di misura
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formUnitOfMeasure"
                            value={product.unitOfMeasure || ""}
                            name="unitOfMeasure"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.unitOfMeasure}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formPrice"
                        className="my-2 font-bold flex items-center"
                    >
                        Prezzo:
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formPrice"
                            value={product.price || ""}
                            name="price"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.price}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formBrand"
                        className="my-2 font-bold flex items-center"
                    >
                        Brand:
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formBrand"
                            value={product.brand || ""}
                            name="brand"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.brand}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formStars"
                        className="my-2 font-bold flex items-center"
                    >
                        Valutazione:
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formStars"
                            value={product.stars || ""}
                            name="stars"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.stars}
                        </span>
                    )}
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formBuyBack"
                        className="my-2 font-bold flex items-center"
                    >
                        Da acquistare:
                        <div className="flex gap-2 ml-2 font-normal">
                            {update ? (
                                <input
                                    id="formBuyBack"
                                    type="checkbox"
                                    name="buyBack"
                                    className="form-tick appearance-none h-6 w-6 border-2 border-gray-900 dark:border-white rounded-md checked:bg-check checked:bg-indigo-600"
                                    checked={product.buyBack || false}
                                    onChange={handleChangeBuyBack}
                                    disabled={update ? false : true}
                                />
                            ) : (
                                ""
                            )}
                            <span className="">
                                {product.buyBack ? "Si" : "No"}
                            </span>
                        </div>
                    </label>
                </div>

                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formPurchasedAt"
                        className="my-2 font-bold flex items-center"
                    >
                        Data d'acquisto:
                    </label>
                    <div className="ml-2 h-fit sm:col-span-2">
                        {update ? (
                            <input
                                id="formPurchasedAt"
                                value={
                                    product.purchasedAt
                                        ? htmlPrintDate(product.purchasedAt)
                                        : ""
                                }
                                name="purchasedAt"
                                onChange={handleChangePurchasedAt}
                                type="date"
                                className="input-secondary"
                                disabled={update ? false : true}
                            />
                        ) : (
                            <span>
                                {product.purchasedAt
                                    ? htmlPrintDate(product.purchasedAt)
                                    : ""}
                            </span>
                        )}
                    </div>
                </div>

                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formExpiredAt"
                        className="my-2 font-bold flex items-center"
                    >
                        Data scadenza:
                    </label>
                    <div className="h-fi">
                        {update ? (
                            <input
                                id="formExpiredAt"
                                value={
                                    product.expiredAt
                                        ? htmlPrintDate(product.expiredAt)
                                        : htmlPrintDate(time.date)
                                }
                                name="expiredat"
                                onChange={handleChangeExpiredAt}
                                type="date"
                                disabled={update ? false : true}
                                className="input-secondary"
                            />
                        ) : (
                            <span>
                                {product.expiredAt
                                    ? htmlPrintDate(product.expiredAt)
                                    : ""}
                            </span>
                        )}
                        <span>Oppure la sua durata</span>
                        {update ? (
                            <div className="flex items-center justify-center gap-4">
                                <input
                                    type="text"
                                    onChange={handleDurationSelect}
                                    className="input-primary"
                                    disabled={update ? false : true}
                                />
                                <select
                                    onChange={handleDurationType}
                                    className="select-primary"
                                    disabled={update ? false : true}
                                >
                                    <option>giorni</option>
                                    <option>mesi</option>
                                </select>
                            </div>
                        ) : (
                            " COMING SOON "
                        )}

                        {update ? (
                            <div>
                                <button
                                    onClick={handleDeleteExpire}
                                    className="btn-primary mt-4 float-right"
                                >
                                    <IconDelete /> Elimina scadenza
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="dropdown-category"
                        className="my-2 font-bold flex items-center"
                    >
                        Categoria:
                    </label>
                    <div className="sm:col-span-2">
                        <DropdownMultiple
                            label="Categoria"
                            placeholder="Seleziona la categoria"
                            optionsList={categoryArray}
                            optionListSelected={categoryArraySelected}
                            handleSelect={handleSelectCategory}
                            setProduct={setProduct}
                            product={product}
                            id="dropdown-category"
                            disabled={update ? false : true}
                        />
                    </div>
                </div>
                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="dropdown-store"
                        className="my-2 font-bold flex items-center"
                    >
                        Negozio:
                    </label>
                    <div className="sm:col-span-2">
                        <DropdownMultiple
                            label="Negozio"
                            placeholder="Seleziona il negozio"
                            optionsList={storeArray}
                            optionListSelected={storeArraySelected}
                            multiple={true}
                            handleSelect={handleSelectStore}
                            setProduct={setProduct}
                            product={product}
                            id="dropdown-store"
                            disabled={update ? false : true}
                        />
                    </div>
                </div>

                <div
                    className={
                        "mt-2 w-full flex " +
                        (update ? "flex-col" : "flex-row gap-2")
                    }
                >
                    <label
                        htmlFor="formNotes"
                        className="my-2 font-bold flex items-center"
                    >
                        Note:
                    </label>
                    {update ? (
                        <input
                            type="text"
                            id="formNotes"
                            value={product.notes || ""}
                            name="notes"
                            onChange={handleChange}
                            className="input-secondary"
                        />
                    ) : (
                        <span className="flex items-center">
                            {product.notes}
                        </span>
                    )}
                </div>
                <div className="flex w-full justify-between align-middle my-4">
                    <span className="flex items-center">
                        Prodotto caricato il : {printDate(product.createdAt)}
                    </span>
                </div>
                <div className="mt-2 w-full flex flex-row justify-between">
                    {update ? (
                        <button type="submit" className="btn-primary">
                            <IconSend /> Invia
                        </button>
                    ) : null}
                    {update ? (
                        <button
                            onClick={() => handleClickDelete(product.code)}
                            type="button"
                            className="btn-primary"
                        >
                            <span className="md:block hidden">
                                <IconDelete />
                            </span>
                            Elimina prodotto
                        </button>
                    ) : null}
                </div>
            </form>
        </>
    );
};

export default ProductUpdate;
