import { IconShop } from "../Icon/Icon";

const Tag = ({ type, children, id, onClick }) => {
    return (
        <button
            onClick={() => onClick(id)}
            className="mr-2 m-1 w-fit whitespace-nowrap flex flex-row items-center gap-1 justify-center rounded border-2 border-solid border-gray-900 dark:border-white px-2"
        >
            {type === "store" ? <IconShop /> : null}
            {children}
        </button>
    );
};

export default Tag;
