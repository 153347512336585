import { Link, useNavigate } from "react-router-dom";
import { userSignin } from "../../services/authServices";

const SignIn = ({ setToken }) => {
    const navigate = useNavigate();

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        let encriptPassword = e.target.elements[1].value;
        let newUser = {
            email: e.target.elements[0].value,
            password: encriptPassword,
        };
        let result = await userSignin(newUser);
        if (result) {
            localStorage.setItem("userEmail", newUser.email);
            localStorage.setItem("userToken", result.token);
            setToken(result.token);
            navigate("/");
        }
    };

    return (
        <>
            <h1 className="text-xl my-4 font-extrabold">Effettua il login</h1>
            <form onSubmit={handleOnSubmit} className="flex flex-col gap-2">
                <div className="flex flex-col">
                    <label htmlFor="email" className="font-bold">Email</label>
                    <input type="text" id="email" className="input-primary" />
                </div>
                <div className="flex flex-col">
                    <label htmlFor="password" className="font-bold">password</label>
                    <input
                    type="password"
                    id="password"
                    className="input-primary"
                />
                </div>
                <button type="submit" className="btn-primary">
                    Accedi
                </button>
            </form>
            <div>
                <span className="">Oppure</span>
                <Link to="/signup" className="btn-primary">
                    Registrati
                </Link>
            </div>
        </>
    );
};

export default SignIn;
