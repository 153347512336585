export const printDate = (date) => {
    let MyDate = new Date(date)
    return date
        ? ('0' + MyDate.getDate()).slice(-2) + '/'
        + ('0' + (MyDate.getMonth()+1)).slice(-2) + '/'
        + MyDate.getFullYear()
        : "Scadenza non definita";
};

export const htmlPrintDate = (date) => {
    let MyDate = new Date(date)

    return MyDate.getFullYear() + '-'
        + ('0' + (MyDate.getMonth()+1)).slice(-2) + '-'
        + ('0' + MyDate.getDate()).slice(-2) 
      ;
}

/* export const sumDays = (myDate, days) => {
    let newDate = new Date(myDate);
    return newDate.setDate(newDate.getDate() + parseInt(days));
}

export const sumMonths = (myDate, months) => {
    var newDate = new Date(myDate)
    return newDate.setMonth(myDate.getMonth() + parseInt(months));
}
 */
export const daysTwoDate = (dateExpire, dataPurchased ) => {
    return parseInt(
        (new Date(dataPurchased) - new Date(dateExpire)) / (1000 * 60 * 60 * 24)
    );
};

export const expirationNotification = (dateToPurchase, dateToExpire, daysToNotification) => {
    // ritorna true se daysToExpire è uguale o minore di daysToNotification
    if(  daysToExpire(dateToPurchase, dateToExpire) <= daysToNotification){
       return true
    }else{
       return false
    }
}

export const daysToExpire = (purchasedAt, expiredAt) => {
    
    /* return parseInt(
        (Date.now() - new Date(expiredAt)) / (1000 * 60 * 60 * 24)
    ); */
    let max = 
        daysTwoDate(
            purchasedAt,
            expiredAt
        )
    
    let value = daysTwoDate(
        purchasedAt,
        Date.now()
    )
    /* console.log(max, value, max-value) */
    return max-value
};

export const calculateExpire = (purchasedAt, dateExpire) => {
    if (dateExpire) {
        let days = daysToExpire(purchasedAt, dateExpire);
        
        if (days >= 0) {
            return `Il prodotto scade tra ${Math.abs(days)} giorni`;
        } else {
            return `Il prodotto è scaduto da ${Math.abs(days)} giorni`;
        }
    }
    return "-";
};

export const getAllProduct = async (token) => {
   /*  console.log(token) */
    let result = await fetch(`${process.env.REACT_APP_URL}/products`,
    {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
    if (result) {
        let jsonResults = await result.json();
        if (jsonResults) {
            return jsonResults.data;
        }
    }
};

export const getAllProductOrdered = async (order, orderBy, token) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/products?order=${order}&order_by=${orderBy}`,
    {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
    
    if (result) {
        let jsonResults = await result.json();
        if (jsonResults) {
            /* console.log(jsonResults) */
            return jsonResults.data;
        }
    }
}

export const getAllProductWhere = async (where, whereBy, token) => {
    /* console.log(where, whereBy) */
    let result = await fetch(`${process.env.REACT_APP_URL}/products?where=${where}&where_by=${whereBy}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
    
    if (result) {
        let jsonResults = await result.json();
        if (jsonResults) {
            /* console.log(jsonResults) */
            return jsonResults.data;
        }
    }
}

export const getAllProductWhereArray = async (where, whereBy, token) => {
    /* console.log(where, whereBy) */
    let result = await fetch(`${process.env.REACT_APP_URL}/products?where=$${where}$&where_by=${whereBy}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
    
    if (result) {
        let jsonResults = await result.json();
        if (jsonResults) {
            console.log("getAllProductWhereArray ->",jsonResults)
            return jsonResults.data;
        }
    }
}

export const getProduct = async (idOrBarcode, token) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/products/${idOrBarcode}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
    if (result) {
        let jsonResults = await result.json();
        if (jsonResults) {
            return jsonResults.data;
        }
    }
};

export const putAPI = async (idOrBarcode, propertyToUpdate, newValue, token) => {
    let newObj = {};
    newObj[propertyToUpdate] = newValue;
    await fetch(`${process.env.REACT_APP_URL}/products/${idOrBarcode}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
        body: JSON.stringify(newObj),
    });
};

export const putAPIFull = async (idOrBarcode, newObj, token) => {
    await fetch(`${process.env.REACT_APP_URL}/products/${idOrBarcode}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
        body: JSON.stringify(newObj),
    });
};

export const deleteAPI = async (idOrBarcode, token) => {
    await fetch(`${process.env.REACT_APP_URL}/products/${idOrBarcode}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
};

export const postProductAPI = async (product, token) => {
    console.log("postProductAPI ", token);
    let result = await fetch(`${process.env.REACT_APP_URL}/products`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
        body: JSON.stringify({
            code: product.code,
            name: product.product_name,
            brand: product.brands,
            quantity: product.quantity,
            notes: "",
        }),
    });
    if (result) {
        let resultsJson = await result.json();
        if (resultsJson) {
            return resultsJson;
        }
    }
};
