const Toggle = ({ label, handleChangeToggle }) => {
    return (
        <>
            <div className="relative inline-block w-10 mr-2 my-1 align-middle select-none">
                <input
                    type="checkbox"
                    name="toggle"
                    id={label}
                    className="checked:bg-indigo-600 checked:right-0 checked:dark:bg-indigo-600 right-4 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white dark:bg-neutral-600 border-2 border-gray-900 dark:border-white appearance-none cursor-pointer"
                    onClick={handleChangeToggle}
                />
                <label
                    htmlFor={label}
                    className="block h-6 overflow-hidden border-2 border-gray-900 dark:border-white dark:bg-neutral-600 rounded-full cursor-pointer"
                ></label>
            </div>
            <span className="font-base text-gray-900 dark:text-white">{label}</span>
        </>
    );
};

export default Toggle;
