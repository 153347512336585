import { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import useLocalStorage from "use-local-storage";
import io from "socket.io-client";
import { toast, ToastContainer } from "react-toastify";
import Layout from "./components/Layout";
import ProtectedRoutes from "./components/Layout/ProtectedRoutes";
import Footer from "./components/Footer";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp";
import { AuthTokenContext } from "./context/AuthTokenContext";

/* const socket = io(
    process.env.REACT_SOCKET_APP_URL+ "/stream"
); */

function App() {
   /*  const [isConnected, setIsConnected] = useState(socket.connected); */

    // theming
    const defaultDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
    ).matches;

    const [theme, setTheme] = useLocalStorage(
        "theme",
        defaultDark ? "dark" : "light"
    );

    const switchTheme = () => {
        const newTheme = theme === "light" ? "dark" : "light";
        setTheme(newTheme);
        document.body.classList = newTheme;
    };

    useEffect(() => {
        document.body.classList = theme;

        // socket notification
        /* socket.on("connect", () => {
            setIsConnected(true);
        });
        socket.on("message", (data) => {
            toast(data.content);
        });

        socket.on("disconnect", () => {
            setIsConnected(false);
        });
        return () => {
            socket.off("message");
            socket.off("disconnect");
        }; */
    }, [theme]);

    const [token, setToken] = useState(() => localStorage.getItem("userToken"));
    useEffect(() => {
        setToken(() => localStorage.getItem("userToken"));
    }, [token]);

    return (
        <BrowserRouter>
            <div className={"transition-colors duration-500 " + theme}>
                {/* <div className="flex justify-end"> {isConnected ? "🟢" : "🔴"}</div> */}
                <ToastContainer role="alert" />
                <AuthTokenContext.Provider value={token}>
                    <Routes>
                        <Route
                            path="/signin"
                            element={<SignIn setToken={setToken} />}
                        />
                        <Route path="/signup" element={<SignUp />} />

                        <Route element={<ProtectedRoutes />}>
                            <Route
                                path="/*"
                                element={<Layout switchTheme={switchTheme} />}
                            />
                        </Route>
                    </Routes>
                </AuthTokenContext.Provider>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
