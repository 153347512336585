
export const getAllCategory = async (token) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/categories`, {
        method: "GET",
        headers: {
            "x-access-token": token,
        },
    });
    let jsonResult = await result.json();
    return jsonResult.data;
};

export const getCategory = async (id, token) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/categories/${id}`, {
        method: "GET",
        headers: {
            "x-access-token": token,
        },
    });
    let jsonResult = await result.json();
    return jsonResult.data;
};

export const putCategoryAPI = async (id, propertyToUpdate, newValue, token) => {
    let newObj = {};
    newObj[propertyToUpdate] = newValue;
    await fetch(`${process.env.REACT_APP_URL}/categories/${id}`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
        body: JSON.stringify(newObj),
    });
};

export const deleteCategoryAPI = async (id, token) => {
    await fetch(`${process.env.REACT_APP_URL}/categories/${id}`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
    });
};

export const postCategoryAPI = async (category, token) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/categories`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "x-access-token": token,
        },
        body: JSON.stringify({
            name: category.name,
            notes: "",
        }),
    });
    let resultJson = await result.json();
    return resultJson;
};
