import { useContext, useEffect, useState } from "react";
import { AuthTokenContext } from "../../context/AuthTokenContext";

import { IconAdd } from "../Icon/Icon";

const SettingsCard = ({ data, postFunc, updateFunc, deleteFunc, title, details, labelNew, buttonNew }) => {
    const [items, setItems] = useState([]);
    const [newItem, setNewItem] = useState("");
    const [updateItem, setUpdateItem] = useState({
        id: "",
        name: "",
        edit: false,
    });

    useEffect(()=>{
        setItems(data)
    }, [data, items])

    const token = useContext(AuthTokenContext)

    const handleAdd = async () => {
        console.log("token in settingsCard -> ", token)
        let newobj = { name: newItem };
        await postFunc(newobj, token);
    };

    const handleChange = (e) => {
        setNewItem(e.target.value);
    };

    const handleDelete = (e, id) => {
        let index = items.findIndex((elem) => elem.id === id)
        let newarray = items.splice(index, 1)
        setItems(newarray)
        deleteFunc(id, token)
    }

    const handleChangeUpdate = (e, id) => {
        setUpdateItem({ id: id, name: e.target.value, edit: true });
    };

    const handleClickUpdate = (e, id) => {
        setUpdateItem({ id: id, name: e.target.value, edit: true });
    };

    

    const handleSave = (e, idToSave) => {
        updateFunc(idToSave, "name", updateItem.name, token);
        document.getElementById(idToSave).disabled = true;
        setUpdateItem({ id: idToSave, name: "", edit: false });
    };

    return (
        <>
            <div className="max-w-2xl h-fit overflow-hidden bg-white shadow sm:rounded-lg border border-gray-300">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {title}
                    </h3>
                    <p className="max-w-2xl mt-1 text-sm text-gray-500">
                        {details}
                    </p>
                </div>
                <div className="border-t border-gray-200">
                    {items && items.map((category, index) => (
                        <dl
                            className="odd:bg-gray-50 even:bg-white"
                            key={index}
                        >
                            <div className="px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6 items-center">
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <input
                                        id={category.id}
                                        defaultValue={
                                            updateItem.id === category.id
                                                ? updateItem.name
                                                : category.name
                                        }
                                        onChange={(e) =>
                                            handleChangeUpdate(e, category.id)
                                        }
                                        disabled={!(updateItem.id === category.id)}
                                        className="disabled:cursor-not-allowed rounded-lg flex-1 appearance-none disabled:border-none border border-gray-500 w-full py-2 px-4 bg-transparent text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                                    />
                                </dd>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex gap-4">
                                    {!(
                                        updateItem.edit &&
                                        category.id === updateItem.id
                                    ) && (
                                        <button
                                            id={"buttonUpdate" + category.id}
                                            onClick={(e) =>
                                                handleClickUpdate(
                                                    e,
                                                    category.id
                                                )
                                            }
                                            className="buttonUpdate py-2 px-4 flex justify-center items-center  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-fit transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                        >
                                            Aggiorna
                                        </button>
                                    )}
                                    {updateItem.edit &&
                                        category.id === updateItem.id && (
                                            <button
                                                id={"buttonSave" + category.id}
                                                onClick={(e) =>
                                                    handleSave(e, category.id)
                                                }
                                                className="py-2 px-4 flex justify-center items-center  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-fit transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                            >
                                                Salva
                                            </button>
                                        )}
                                    <button
                                        onClick={(e) =>
                                            handleDelete(e,category.id)
                                        }
                                        className="py-2 px-4 flex justify-center items-center  bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white w-fit transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                    >
                                        Elimina
                                    </button>
                                </dd>
                            </div>
                        </dl>
                    ))}
                </div>
                <div className="px-4 py-5 sm:px-6">
                    <form className="flex gap-3 items-center flex-col md:flex-row">
                        <div>
                            <label
                                className="text-sm font-medium text-gray-500"
                                htmlFor="inputCategory"
                            >
                                {labelNew}
                            </label>
                            <input
                                id="inputCategory"
                                type="text"
                                onChange={handleChange}
                                className="rounded-lg flex-1 appearance-none border border-gray-500 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
                            />
                        </div>
                        <button
                            type="submit"
                            onClick={handleAdd}
                            className="self-end py-2 px-4 flex justify-center items-center  bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white w-fit transition ease-in duration-200 text-center  font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                        >
                            <IconAdd />{buttonNew}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SettingsCard;