import { element } from "prop-types";
import { useCallback, useContext, useEffect, useState } from "react";
import {
    Route,
    Routes,
} from "react-router-dom";
import { AuthTokenContext } from "../../context/AuthTokenContext";
import {
    expirationNotification,
    getAllProduct,
} from "../../services/productsService";
import Header from "../Header";
import Main from "../Main";
import ProductList from "../ProductList";
import Profile from "../Profile/Profile";
import Settings from "../Settings/Settings";

const Layout = ({ switchTheme }) => {
    const [products, setProducts] = useState([]);
    const [productsExpiry, setProductsExpiry] = useState([]);
    const [productsToBuy, setProductsToBuy] = useState([]);
    const daysToNotification = 7;
    const token = useContext(AuthTokenContext);
    const [sumProductToBuy, setSumProductToBuy] = useState(0)
    const calculateManyDays = useCallback(async () => {
        let result = await getAllProduct(token);
        let filteredProducts = result.filter(
            (product) =>
                expirationNotification(
                    new Date(Date.now()),
                    product.expiredAt,
                    7
                ) === true && product.expiredAt
        );
        setProductsExpiry(filteredProducts);
        filteredProducts = result.filter((product) => product.buyBack === true);
        setProductsToBuy(filteredProducts);
        let sum = 0;
        filteredProducts.map((elem) =>  (elem.price) ? sum = sum + parseFloat(elem.price) : sum = sum + 0) 
        setSumProductToBuy(Number.parseFloat(sum).toFixed(2))
    }, [token]);

    useEffect(() => {
        const setAllProduct = async () => {
            let result = await getAllProduct(token);
            setProducts(result);
        };
        setAllProduct();
    }, [token]);

    useEffect(() => {
        calculateManyDays();
    }, [products, calculateManyDays]);

    return (
        <>
            <Header
                switchTheme={switchTheme}
                manyProductsExpiry={productsExpiry.length}
                manyProductsToBuy={productsToBuy.length}
                
            />
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <Main
                                products={products}
                                setProducts={setProducts}
                            />
                        </>
                    }
                />
                <Route
                    path="/expiring-products-list"
                    element={
                        <>
                            <h2 className="text-xl my-4 font-extrabold">
                                Prodotti in scadenza
                            </h2>
                            <ProductList
                                products={productsExpiry}
                                setProducts={setProducts}
                                show={{
                                    details: true,
                                    quantity: true,
                                    toBuy: true,
                                    quantityToBuy: false,
                                    expire: true,
                                    actions: true,
                                }}
                            />
                        </>
                    }
                />
                <Route
                    path="/shopping-list"
                    element={
                        <>
                            <h2 className="text-xl my-4 font-extrabold">
                                Lista della spesa
                            </h2>
                            <div className="py-2">Spesa totale: {sumProductToBuy}</div>
                            <ProductList
                                products={productsToBuy}
                                setProducts={setProducts}
                                show={{
                                    details: true,
                                    quantity: false,
                                    toBuy: true,
                                    quantityToBuy: true,
                                    expire: false,
                                    actions: true,
                                }}
                            />
                        </>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <Settings daysToNotification={daysToNotification} />
                    }
                />

                <Route
                    path="/profile"
                    element={<Profile switchTheme={switchTheme} />}
                />
            </Routes>
        </>
    );
};

export default Layout;

export const useAuth = () => {
    return useContext(AuthTokenContext);
};
