import { useNavigate } from "react-router-dom";
import { IconLogout, IconTheme } from "../Icon/Icon";

const Profile = ({ switchTheme }) => {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("userEmail");
        localStorage.removeItem("userToken");
        navigate(0);
        navigate("/");
        navigate(0);
    };
    return (
        <>
            <h2 className="text-xl my-4 font-extrabold">Profilo utente</h2>
            <div className="flex justify-end gap-4">
                <button onClick={switchTheme} className="btn-secondary">
                    <IconTheme width="1.5rem" height="1.5rem" />
                </button>
                <button
                    type="button"
                    className="btn-secondary"
                    onClick={logout}
                >
                    <IconLogout width="1.5rem" height="1.5rem" />
                </button>
            </div>
        </>
    );
};

export default Profile;
