export const userSignin = async (user) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/auth/signin`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            email: user.email,
            password: user.password,
        }),
    })
    let resultJson = await result.json()
    return resultJson
}


export const userSignup = async (user) => {
    let result = await fetch(`${process.env.REACT_APP_URL}/auth/signup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            password: user.password,
        }),
    })
    let resultJson = await result.json()
    return resultJson
}
