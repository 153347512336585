import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import {
    IconSearch,
    IconSetting,
    IconShop,
    IconUser,
    IconWarning,
} from "../Icon/Icon";

const Header = ({ manyProductsExpiry, manyProductsToBuy }) => {
    /* 
    const navigate = useNavigate();
    const refreshPage = () => {
        navigate(0);
        navigate("/");
        navigate(0);
    };
    */
    return (
        <header className="flex flex-col justify-between">
            <div className="flex flex-row justify-between gap-1">
                <h1 className="text-3xl font-extrabold">Dispensa</h1>
                <div className="flex gap-2">
                    {/* <button className="flex items-center p-4  transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 ">
                            <IconNotification />
                        </button> */}

                    <NavLink
                        to="/settings"
                        className={({ isActive }) =>
                            isActive
                                ? "bg-indigo-200 dark:bg-indigo-600 rounded-full"
                                : "dark:bg-neutral-700 rounded-full"
                        }
                    >
                        <button type="button" className="btn-secondary">
                            <IconSetting width="1.5rem" height="1.5rem" />
                        </button>
                    </NavLink>

                    <NavLink
                        to="/profile"
                        className={({ isActive }) =>
                            isActive
                                ? "bg-indigo-200 dark:bg-indigo-600 rounded-full"
                                : "dark:bg-neutral-700 rounded-full"
                        }
                    >
                        <button type="button" className="btn-secondary">
                            <IconUser width="1.5rem" height="1.5rem" />
                        </button>
                    </NavLink>
                </div>
            </div>
            <div className="flex flex-row flex-wrap gap-4 my-4">
                <NavLink
                    to="/"
                    className={({ isActive }) =>
                        isActive
                            ? "bg-indigo-200 dark:bg-indigo-600 rounded-lg"
                            : "dark:bg-neutral-700 rounded-lg"
                    }
                >
                    <button
                        type="button"
                        className="btn-primary"
                        /* onClick={refreshPage} */
                    >
                        <span className="flex">
                            <IconSearch width="1.5rem" height="1.5rem" />
                        </span>
                        Ricerca
                    </button>
                </NavLink>
                <div className="flex flex-row justify-between gap-4">
                    <NavLink
                        to="/expiring-products-list"
                        className={({ isActive }) =>
                            isActive
                                ? "bg-indigo-200 dark:bg-indigo-700 rounded-lg"
                                : "dark:bg-neutral-700 rounded-lg"
                        }
                    >
                        <button type="button" className="btn-primary">
                            <span className="flex">
                                <IconWarning width="1.5rem" height="1.5rem" />
                            </span>
                            <span>
                                Prodotti in scadenza ({manyProductsExpiry})
                            </span>
                        </button>
                    </NavLink>
                    <NavLink
                        to="/shopping-list"
                        className={({ isActive }) =>
                            isActive
                                ? "bg-indigo-200 dark:bg-indigo-700 rounded-lg"
                                : "dark:bg-neutral-700 rounded-lg"
                        }
                    >
                        <button type="button" className="btn-primary">
                            <span className="flex">
                                <IconShop width="1.5rem" height="1.5rem" />
                            </span>
                            <span>Lista della spesa ({manyProductsToBuy})</span>
                        </button>
                    </NavLink>
                </div>
            </div>
        </header>
    );
};

Header.propTypes = {
    switchTheme: PropTypes.func,
};

export default Header;
