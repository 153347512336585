import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthTokenContext } from "../../context/AuthTokenContext";
import { useAuth } from "./Layout";

/* const useAuth = () => {
    const user = localStorage.getItem("user");
    if (user) {
        return true;
    } else {
        return false;
    }
};
 */
const ProtectedRoutes = () => {
    /* const auth = useAuth(); */
   /*  const token = localStorage.getItem("userToken") 
    console.log("token ->", token) */
  /*  const token = useAuth() */
  
  const token = useContext(AuthTokenContext)
  /* console.log("token2 ->", value)
   console.log(token) */
   
    return token ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
