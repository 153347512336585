import React, { useEffect } from "react";
import ReactModal from "react-modal";

const Modal = ({ modalIsOpen, setIsOpen, children, position, title }) => {
    let classCommon =
        "absolute bg-white dark:bg-neutral-800 lg:top-12 lg:right-10 p-8 z-{2} w-full lg:w-1/3 overflow-y-scroll overflow-x-hidden border-solid border-2 border-gray-900 dark:border-white rounded-lg";
    let classHalfPage = classCommon + "  h-full lg:h-5/6  ";
    let classCentral = classCommon + "  ";

    useEffect(() => {}, [modalIsOpen]);

    return (
        <div id={`modal-${title}`}>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel={title}
                ariaHideApp={false}
                className={
                    position === "central" ? classCentral : classHalfPage
                }
            >
                {children}
            </ReactModal>
        </div>
    );
};

export default Modal;
