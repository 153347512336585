import { useContext, useState } from "react";
import { toast } from "react-toastify";
import Search from "../Search";
import {
    getAllProduct,
    getProduct,
    postProductAPI,
    putAPIFull,
} from "../../services/productsService";
import { getOpenFoodFacts } from "../../services/openFoodFactsAPI";
import Modal from "../Modal";
import ProductList from "../ProductList";
import { IconClose } from "../Icon/Icon";
import { AuthTokenContext } from "../../context/AuthTokenContext";

const Main = ({ products, setProducts }) => {

    const [code, setCode] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);
    const [optionToggle, setOptionToggle] = useState(false)

    const notifyUpdate = () =>
        toast.success(`codice= ${code} è stato aggiornato`);
    const notifyError = () =>
        toast.error(`codice= ${code} non esiste nel db e nelle api esterne`);
    const notifyPost = () => toast.success(`codice= ${code} è stato aggiunto`);
    const token = useContext(AuthTokenContext)

    const setAllProduct = async () => {
        let result = await getAllProduct(token);
        await setProducts(result);
    };

    const updateProductAdd = async (code) => {
        let product = await getProduct(code, token);
        if (typeof product === "undefined") {
            let checkIfExistsOnExternalApi = await getOpenFoodFacts(code);
            if (typeof checkIfExistsOnExternalApi === "undefined") {
                notifyError();
            } else {
                /* putAPI(product.code, "quantity", 1); */
                // creo un nuovo prodotto con quel codice e imposto la quantità a 1
                let newArray = [...products];
                let prodFind = products.findIndex((prod) => prod.code === code);
                newArray[prodFind].quantity = 1;
                await putAPIFull(prodFind.id, newArray[prodFind], token);
                setProducts(newArray);
                notifyUpdate();
            }
        } else {
            /* putAPI(product.code, "quantity", parseInt(product.quantity) + 1); */
            // il prodotto esiste nel db, lo aggiorno di una quantità
            let newArray = [...products];
            let prodFind = products.findIndex((prod) => prod.code === code);
            newArray[prodFind].quantity = parseInt(newArray[prodFind].quantity) + 1;
            await putAPIFull(prodFind.id, newArray[prodFind], token);
            setProducts(newArray);
            notifyUpdate();
        }
    };

    const addNewProduct = async () => {
        let oggettoProdotto = await getOpenFoodFacts(code);
        console.log("oggettoProdotto: ", oggettoProdotto)
        console.log("code: ",code)
        if(!oggettoProdotto) {console.log("il prodotto non esiste nel db e voglio aggiungerlo"); oggettoProdotto = {code: "codice da inserire", product_name: code}}
        if (optionToggle === "search") oggettoProdotto.quantity = 0;
        else oggettoProdotto.quantity = 1;
        
        await postProductAPI(oggettoProdotto, token);
        setAllProduct();
        notifyPost();
    };

    const handleChangeSearch = (e) => {
        console.log("handleChangeSearch -> ",e.target.value)
        setCode(e.target.value);
    };

    const handleChangeToggle = (e) => {
        setOptionToggle(e.target.checked)
    }

    const handleSubmit = async (e) => {
        console.log("handleSubmit")
        e.preventDefault();
        let checkIfExist = await getProduct(code, token);
        console.log("checkIfExist -> ", checkIfExist)
        if (typeof checkIfExist === "undefined") {
            let checkIfExistsOnExternalApi = await getOpenFoodFacts(code);
            typeof checkIfExistsOnExternalApi === "undefined"
                ? /* notifyError() */ setIsOpen(true)
                : setIsOpen(true);
        } else {
            if(optionToggle){
                // se true allora search-update
                updateProductAdd(code);
            }
        }
    };

    const handleClickYes = () => {
        addNewProduct(code);
        setAllProduct();
        setIsOpen(false);
    };
    
    return (
        <main className="my-4">
            <form onSubmit={handleSubmit}>
                <Modal
                    modalIsOpen={modalIsOpen}
                    setIsOpen={setIsOpen}
                    position="central"
                    title="accept"
                >
                    <button
                    onClick={() => setIsOpen(false)}
                    className="btn-primary"
                    >
                        <IconClose />
                        Chiudi
                    </button>
                    <h2 className="flex justify-center py-10 text-lg">Vuoi aggiungere il prodotto nel db?</h2>
                    <div className="flex justify-center gap-10">
                        <button onClick={handleClickYes} className="py-2 px-4 flex gap-1 justify-center items-center bg-gray-100 hover:bg-gray-700 hover:text-white focus:ring-gray-500 focus:ring-offset-gray-200 text-gray-700 w-fit transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg border border-gray-500">Si</button>
                        <button onClick={() => setIsOpen(false)} className="py-2 px-4 flex gap-1 justify-center items-center  bg-white hover:bg-gray-700 hover:text-white focus:ring-gray-500 focus:ring-offset-gray-200 text-gray-700 w-fit transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg border">No</button>
                    </div>
                </Modal>
                <Search
                    code={code}
                    setCode={setCode}
                    handleChangeToggle={handleChangeToggle}
                    handleChangeSearch={handleChangeSearch}
                    setProducts={setProducts}
                />
               
            </form>
            <ProductList
                products={products}
                setProducts={setProducts}
                show={{"details": true, "quantity": true, "toBuy": true, "quantityToBuy": false, "expire": true, "actions": true}}
            />
        </main>
    );
};

export default Main;
